import React from 'react'
import TestmonialsStyle from './style'
import { Stroke_Text } from '../globalstyles/global'
import {testimonialsData} from '../../data/testimonialsData'
import arrowleft from '../../assets/leftArrow.png'
import arrowright from '../../assets/rightArrow.png'
import { useState } from 'react'
import { motion } from 'framer-motion'
const Testimonials = () => {
    const transition={type:"spring",duration:"3"}
    const[selected,setSelected]=useState(0)
    const isLength=testimonialsData.length;
  return (
    <TestmonialsStyle>
        <section className='Testmonials'id='testimonials'>
            <article className="left-t">
                <span>Testmonials</span>
                <Stroke_Text>What they</Stroke_Text>
                <span>say about us</span>
                <motion.span
                key={selected}
                initial={{opacity:0,x:100}}
                animate={{opacity:1,x:0}}
                exit={{opacity:0,x:-100}}
                transition={transition}
                >
                    {testimonialsData[selected].review}
                </motion.span>
                <span>
                    {testimonialsData[selected].name} 
                    
                     <span>{"  "}
                        - {testimonialsData[selected].status}
                    </span>
                </span>
            </article>

            <article className="right-t">
                <motion.div
                initial={{opacity:0,x:-100}}
                transition={{...transition,duration:2}}
                whileInView={{opacity:1,x:0}}
                ></motion.div>
                  <motion.div
                initial={{opacity:0,x:100}}
                transition={{...transition,duration:2}}
                whileInView={{opacity:1,x:0}}
                ></motion.div>
                <motion.img 
                key={selected}
                initial={{opacity:0,x:100}}
                animate={{opacity:1,x:0}}
                exit={{opacity:0,x:-100}}
                transition={transition}
                src={testimonialsData[selected].image} alt="" />
                <div className='arrows'>
                    <img 
                    onClick={()=>
                    selected===0?setSelected(isLength-1):setSelected((prev)=>prev-1)}
                    src={arrowleft} alt="" /><img 
                    onClick={()=>selected===isLength-1?setSelected(0):setSelected((prev)=>prev+1)}
                    src={arrowright} alt="" />
                </div>
            </article>

        </section>

    </TestmonialsStyle>
  )
}

export default Testimonials