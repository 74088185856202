import { Blurr, GeneralButton, Stroke_Text } from '../globalstyles/global'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import HeroStyled from './style'
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter'
const Hero = () => {
  const transition={type:'spring',duration:3};
  const mobile=window.innerWidth<=768 ?true:false
  return (
    <HeroStyled>
    <div className='hero' id='home'>
      <Blurr className='hero-blurr'></Blurr>
        <section className="left-hero">
            <Header/>
            {/* the best ad */}
            <div className="the-best-ad">
              <motion.div
            initial={{left:mobile?"166px":"238px"}}
            whileInView={{left:"9px"}}
            transition={{...transition,type:'tween'}}

              ></motion.div>
              <span>the best fitness club in the town</span>
            </div>
            {/* hero-text */}
            <div className="hero-text">
              <div>
                <Stroke_Text>
                  Shape </Stroke_Text>
                <span>Your</span>
              </div>
                <div>
                  <span>Ideal Body</span>
                </div>
                <div>
                  <span>in here we help you to share and bulid your ideal body and live up your life to fullest</span>
                </div>
            </div>
            {/* figure */}
            <div className="figures">
              {/* first div */}
              <div>
                <span>
                  <NumberCounter
                  end={140} start={100} delay='4' preFix="+"
                  />
                </span>
                <span>expert coachs</span>
              </div>
              {/* second div */}
              <div>
                <span>
                <NumberCounter
                  end={978} start={800} delay='4' preFix="+"
                  />
                </span>
                <span>members joined</span>
              </div>
              {/* third div */}
              <div>
                <span>
                <NumberCounter
                  end={50} start={20} delay='4' preFix="+"
                  />
                </span>
                <span>fitness programs</span>
              </div>
              {/* end */}
            </div>
              {/* hero button */}
            <div className="hero-button">
                <GeneralButton className='btn-general'>Get started</GeneralButton>
                <GeneralButton className='btn-general'>Learn More</GeneralButton>
              </div>s
        </section>
        {/* right side */}
        <section className="right-hero">
            <GeneralButton className='btn-join_now'>Join Now</GeneralButton>
            <motion.div 
            initial={{right:'-1rem'}}
            whileInView={{right:"4rem"}}
            transition={transition}
            className="heart-rate">
              <img src={heart} alt="" />
              <span>heart Rate</span>
              <span>116 bpm</span>
            </motion.div>
            {/* hero images */}
            <motion.img
            
             src={hero_image} alt=""  className='hero-img'/>
            <motion.img
            initial={{right:"11rem"}}
            whileInView={{right:'20rem'}}
            transition={transition}
             src={hero_image_back} alt="" className='hero_image-back'/>
            {/* calories */}
            <motion.div
            initial={{right:"37rem"}}
            whileInView={{right:"28rem"}}
            transition={transition}
             className="calories">
              <img src={calories} alt="" />
              <div>
              <span>calories Burned</span>
              <span>220 kcal</span>
              </div>
            </motion.div>
        </section>
    </div>
    </HeroStyled>
  )
}

export default Hero