import styled from "styled-components";
import { Gray, Orange } from "../globalstyles/global";

const ProgramsStyled=styled.div`
.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
    // header
    .programs-header{
     display: flex;
     gap: 5rem;
     justify-content: center;
     color: white;
     font-size: 3rem;
     font-weight: bold;
     text-transform: uppercase;
     font-style: italic;
     @media screen and (max-width:768px){
            flex-direction: column;
            gap: 1rem;
            font-size: x-large;
            align-items: center;
            margin-top: 2rem;
        }
}
.programs-categories{
    display: flex;
    gap: 1rem;
    @media screen and (max-width:768px){
        flex-direction: column;
        
        }
    .category{
        display: flex;
        flex-direction: column;
        background-color: ${Gray};
        padding: 2rem;
        font-size: 2rem;
        gap: 1rem;
        justify-content: space-between;
        color: white;
        &:hover{
            background-color: ${Orange};
            cursor: pointer;
        }
        >:nth-child(1){
            width: 2rem;
            height: 2rem;
            fill: white;
        }
        >:nth-child(2){
            font-size: 1rem;
            font-weight: bold;
        }
        
        >:nth-child(3){
            font-size: 0.9rem;
            line-height: 25px;
                  }
        .join-now{
            display: flex;
            gap: 2rem;
            align-items: center;
            >span:nth-child(1){
                font-size: 1.2rem;
            }
            img{
                width: 1rem;
            }
        }

    }
}
}


`


export default ProgramsStyled