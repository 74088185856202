import React from 'react'
import { Stroke_Text } from '../globalstyles/global'
import ProgramsStyled from './style'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const program = () => {
  return (
    <ProgramsStyled>
     <section className='programs d-flex flex-column' id="programs">
{/* header */}
<header className='programs-header'>
    <Stroke_Text>Explore</Stroke_Text>
    <span>Programs</span>
    <Stroke_Text>to shape you</Stroke_Text>
</header>
<article className='programs-categories'>
{programsData.map(({image,heading,details})=>{
  return(
    <div className="category">
      {image}
      <span>{heading}</span>
      <span>{details}</span>
      <div className='join-now'>
<span>join now</span>
<span><img src={RightArrow} alt="" /></span>
      </div>
    </div>
  )
})}  

</article>
     </section>
    </ProgramsStyled>
  )
}

export default program