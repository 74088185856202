import React from 'react'
import JoinStyled from './joinstyle'
import {GeneralButton, Stroke_Text} from '.././globalstyles/global'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
const Join = () => {
    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_jcz5q8r', 'template_raz9qrv', form.current, '265FTBkjSmi51K1lP')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <JoinStyled >
        <div className='join' id='join-us'>
        <section className='left-j'>
            <hr />
            <div>
                <Stroke_Text>READY TO</Stroke_Text>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <Stroke_Text>WITH US?</Stroke_Text>
            </div>
        </section>
        <section className='right-j'>
            <form ref={form} action="" className="email-container" onSubmit={sendEmail}>
                <input type="email" name='user_email'   placeholder='Enter your Email Address' />
                <GeneralButton className='btn-join'>join now</GeneralButton>
            </form>
        </section>
        </div>
    </JoinStyled>
  )
}

export default Join