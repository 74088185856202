import './App.css';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Programs/Program'
import Reasons from './Components/Reasons/Reason'
import Plans from './Components/PlanCard/plan'
import Testimonials from './Components/Testimonials/Testimonials';
import Join from './Components/Join/Join'
import Footer from './Components/Footer/Footer';
const App = () => {
  return (
    <div className='App'>
<Hero/>
<Programs/>
<Reasons/>
<Plans/>
<Testimonials/>
<Join/>
<Footer/>
    </div>
  )
}

export default App