import styled from "styled-components";
import { Orange } from "../globalstyles/global";

const ReasonsStyle=styled.div`
.reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    @media screen and (max-width:768px){
        flex-direction: column;
        }
}
.left-side-reasons{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
    @media screen and (max-width:768px){
            grid-auto-rows:auto;
            overflow: hidden;

        }
    >img{
        object-fit: cover;
    }
    >:nth-child(1){
        width: 12rem;
        grid-row: 1/3;
        height: 28rem;
        @media screen and (max-width:768px){
            width: 7rem;
            height: 17rem;
        }
    }
    >:nth-child(2){
        width: auto;
        height: 16rem;
        grid-column: 2/4;
        @media screen and (max-width:768px){
            width: 15rem;
            height: 10rem;
        }

    }
    >:nth-child(3){
        width: 14rem;
        grid-column: 2/3;
        grid-row: 2;
        @media screen and (max-width:768px){
            width: 7rem;
        }
    }
    >:nth-child(4){
        width: 10rem;
        grid-row: 2;
        grid-column: 3/4;height:11.5rem;
        @media screen and (max-width:768px){
            width: 7rem;
            height: 6rem;
        }
    }
}
.right-side-reason{
    flex: 1 1;
    text-transform: uppercase;
    display: flex;
    flex-direction:column;
    gap: 1rem;
    >span{
        color:${Orange};
        font-weight: bold;
    }
    >div{
        color: white;
        font-size: 3rem;
        font-weight: bold;
    }
    .details{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: white;
     div{
        display: flex;
        font-size: 1rem;
        gap: 1rem;  
        img{
            width: 2rem;
            height: 2rem;
        }
     }
}
.partners{
    display: flex;
    gap: 1rem;
    img{
        width:2.5rem;
    }
}
}



` 
export default ReasonsStyle