import React from 'react'
import ReasonsStyle from './reasonstyle'
import nike from '../../assets/nike.png'
import tick from '../../assets/tick.png'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'
import {  Stroke_Text } from '../globalstyles/global'
const Reason = () => {
  return (
    <ReasonsStyle>
     <section className="reasons" id="reasons">
<article className="left-side-reasons">
    <img src={image1} alt="" />
    <img src={image2} alt="" />
    <img src={image3} alt="" />
    <img src={image4} alt="" />
</article>
<article className="right-side-reason">
    <span>some reasons</span>
    <div>
        <Stroke_Text>why </Stroke_Text>
        <span> choose us?</span>
    </div>
    <section className='details'>
        <div>
            <img src={tick} alt={tick} />
            <span>Over 140+ EXPERT COACHS</span>
        </div>
        <div>
            <img src={tick} alt={tick} />
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
        </div>
        <div>
            <img src={tick} alt={tick} />
            <span>
                1 FREE PROGRAM FOR NEW MEMBER
            </span>
        </div>
        <div>
            <img src={tick} alt={tick} />
            <span>RELIABLE PARTNERS</span>
        </div>
        <span
        style={
            {color:"#9c9c9c",
            fontWeight:'normal'}
        }
        >OUR PARTNERS</span>
        <section className="partners">
            <img src={nb} alt="" />
            <img src={adidas} alt="" />
            <img src={nike} alt="" />
        </section>
    </section>
</article>
     </section>
</ReasonsStyle>
  )
}

export default Reason