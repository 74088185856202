import styled from "styled-components";
import { Lightgray, Orange } from "../globalstyles/global";

const JoinStyled=styled.div`
.join{
display: flex;
padding: 0 2rem;
gap: 10rem;
@media screen and (max-width:768px){
       flex-direction: column;
       gap: 1rem;
        }
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    @media screen and (max-width:768px){
        font-size: x-large;
        flex-direction: column;
        }
    hr{
        position: absolute;
        width: 10.5rem;
        border:2px solid ${Orange};
        border-radius: 20%;
        margin:-10px 0;
    }
    div{
        display: flex;
        gap: 1rem;
    }
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width:768px){
        padding:2rem;
        }
}
.email-container{
display: flex;
 gap: 3rem;
 background-color: gray;
 padding:1rem 2rem;
 input{
    background-color: transparent;
    border: none;
    outline: none;
    color: ${Lightgray};
 }
 ::placeholder{
    color: ${Lightgray};
 }
 .btn-join{
   background-color: ${Orange};
   color: white;
 }
}

`

export default JoinStyled