import styled from "styled-components";

export  const Lightgray="#D9D9D9";
  export const Gray="#9c9c9c";
  export const Orange="#f48915";
  export const DarkGrey="#464D53";
  export const CaloryCard ="#656565";
  export const planCard="linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%)";
  export const appColor="#3c3f45";

  export const Stroke_Text=styled.span`
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width:1px ;
  -webkit-text-stroke-color:white ;
  `
  export const GeneralButton=styled.button`
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
  `
  export const Blurr=styled.div`
  background: rgba(253,120,43,0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
  `