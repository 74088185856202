import styled from "styled-components";
import {Lightgray } from '../globalstyles/global'
const Footerstyled=styled.div`
.footer-blurr1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.footer-blurr2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px) !important;
    background: rgba(253,120,43,0.69);
}
.footer-container{
    position: relative;
    hr{
        border: 1px solid ${Lightgray};
    }
    
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
    img{
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }
    .logo{
        img{
            width: 10rem
            ;
        }
    }
}

`

export default Footerstyled