import React from 'react'
import HeaderStyled from './headerstyle'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { useState } from 'react'
import { Link } from 'react-scroll'
const Header = () => {
  const mobile=window.innerWidth<=768 ?true:false
  const[menuOpened,setmenuOPened]=useState(false)
  return (
    <HeaderStyled>
    <div className='header'>
          <img src={Logo
        } alt="logoweb" className='logo'/>
        {menuOpened===false && mobile===true?
        <div style={{backgroundColor:"#3c3f45",padding:"0.5rem",borderRadius:"5px",cursor:"pointer"}}
        onClick={()=>setmenuOPened(true)}
        className='menu'><img src={Bars} alt="" style={{width:'1.5rem',height:"1.5rem"}}/></div>:    
        <ul className='header-menu'>
        <li onClick={()=>setmenuOPened(false)}>
        <Link
        to="home"
        span={true}
        smooth={true}
        onClick={()=>setmenuOPened(false)}
        > Home</Link>
          </li>
        <li onClick={()=>setmenuOPened(false)}>
        <Link
        to="programs"
        span={true}
        smooth={true}
        onClick={()=>setmenuOPened(false)}
        > Program</Link></li>
        <li onClick={()=>setmenuOPened(false)}>
        <Link
        to="reasons"
        span={true}
        smooth={true}
        onClick={()=>setmenuOPened(false)}
        > Why us</Link> 
          </li>
        <li onClick={()=>setmenuOPened(false)}>
        <Link
        to="plans"
        span={true}
        smooth={true}
        onClick={()=>setmenuOPened(false)}
        > Plans</Link>
          </li>
        <li onClick={()=>setmenuOPened(false)}>
        <Link
        to="testimonials"
        span={true}
        smooth={true}
        onClick={()=>setmenuOPened(false)}
        > Testimonials</Link>
         </li>
    </ul>}
       
    </div>
    </HeaderStyled>
  )
}

export default Header