import styled from 'styled-components'
import { Orange, planCard } from '../globalstyles/global'

const TestmonialsStyle=styled.div`
.Testmonials{
    display: flex;
gap: 1rem;
padding: 0 2rem;
@media screen and (max-width:768px){
        flex-direction: column;
        }
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    text-transform: uppercase;
    flex-direction: column;
    color:white;
    span:nth-child(1){
        color: ${Orange};
        font-weight: bold;
    }
    span:nth-child(2),span:nth-child(3){
        font-size: 3rem;
        font-weight: bold;
        @media screen and (max-width:768px){
            font-size: xx-large;
        }
    }
    span:nth-child(4){
        text-transform: none;
        text-align: justify;
        letter-spacing: 2px;
        line-height: 40px;

    }
   
    
}
.right-t{
    flex: 1 ;
    position: relative;
    @media screen and (max-width:768px){
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        }
    >img{
        position: absolute;
        height: 20rem;
        width: 17rem;
        object-fit: cover;
        right: 8rem;
        top: 2rem;
        @media screen and (max-width:768px){
       top: 0;
       right: 0;
       position: relative;
       align-self: center;
        }
   }
    }
  div:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid ${Orange};
    background-color: transparent;
    right: 9rem;
    top:0.9rem;
    @media screen and (max-width:768px){
        position: relative;
        display: none;
        }
   }
   div:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4rem;
    background: ${planCard};
    right: 7rem;
    @media screen and (max-width:768px){
        position: relative;
        display: none;
        }
   }
   }
} 
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 3rem;
    bottom: 1rem;
    
    img{
        width: 1.5rem;
        cursor: pointer;
    }
   }
   .right-t{
:last-child{
    @media screen and (max-width:768px){
        display: block;
        bottom: 0;
        left: 0;
    }
}
    }
`
export default TestmonialsStyle