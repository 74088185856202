import styled from "styled-components";
import { appColor, Orange } from "../globalstyles/global";


const HeaderStyled=styled.div`
.header{
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:768px){
      .menu{
        position: fixed;
        right: 2rem;
        z-index: 99;
       }
       ul{
        position: fixed;
        right: 2rem;
        z-index: 99;

       }
      }
      
    // header
.logo{
   width:10rem;
   height: 3rem;
}
// logo
.header-menu{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
  @media screen and (max-width:768px){
        flex-direction: column;
        background-color:${appColor};
        padding: 2rem;
      }
  >li:hover{
    cursor: pointer;
    color:${Orange };
  }
}
// header-menu

}
`

export default HeaderStyled