import styled from "styled-components";
import { CaloryCard, Orange, planCard } from "../globalstyles/global";
const PlansStyle=styled.div`
.plan-blurr1{
    height: 32rem;
    width:23rem;
    top: 6rem;
    left: 0rem;
}
.plan-blurr2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}
.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
    @media screen and (max-width:768px){
            flex-direction:column ;
        }

}
.programs-header{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    @media screen and (max-width:768px){
        flex-direction: column;
        font-size: 1.5rem;
        }
}
.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    @media screen and (max-width:768px){
        flex-direction: column;
        }
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: ${CaloryCard};
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    :nth-child(2){
        background: ${planCard};
        transform: scale(1.1);
        font-size: 1rem;
        font-weight: bold;
        @media screen and (max-width:768px){
        transform: none;
        }
        svg{
            fill: white;
        }
        button{
            color: ${Orange};
        }
    }
    svg{
        fill: ${Orange};
        width: 2rem;
        height: 2rem;
    }
    span:nth-child(1){
        font-size: 1rem;
    }
   span:nth-child(3){
        font-size: 3rem;
        font-weight: bold;
    }
   span:nth-child(5){
        font-size: 0.8rem;

    }
    .features{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .feature{
            display: flex;
            align-items: center;
            gap: 1rem;
            img{
                width: 1rem;
            }
        }
    }
    
}
`
export default PlansStyle