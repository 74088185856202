import React from 'react'
import Footerstyled from './footerstyle'
import GitHub from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkedien from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
import { Blurr } from '../globalstyles/global'
const Footer = () => {
  return (
    <Footerstyled>
<div className="footer-container">
    <hr />
    <section className="footer">
        <div className="social-links">
    <img src={GitHub} alt="" />
    <img src={Instagram} alt="" />
    <img src={Linkedien} alt="" />
    </div>
    {/* logo */}
    <div className="logo">
        <img src={Logo} alt="" />
        </div>
    </section>
    <Blurr className='footer-blurr1'></Blurr>
    <Blurr className='footer-blurr2'></Blurr>
</div>
    </Footerstyled>
  )
}

export default Footer