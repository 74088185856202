import styled from "styled-components";
import { CaloryCard, DarkGrey, Gray, Orange } from "../globalstyles/global";
const HeroStyled=styled.div`
.hero-blurr{
    width:22rem;
    height: 30rem;
    left:0;
}
.hero{
    display: flex;
    justify-content: space-between;
 // hero   
.left-hero{
    padding: 2rem;
    padding-top:1.5rem ;
    flex:3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
// left-hero

.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >span{
        z-index: 2;
    }
    >div{
        position: absolute;
        background-color: ${Orange};
        width: 5.4rem;
        height: 80%;
        left: 8px;
        border-radius: 3rem;
        z-index: 1;
    }
}
// hero-text
.hero-text{
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
    >div:nth-of-type(3){
        font-size: 1rem;
        font-weight: 200;
        letter-spacing: 1px;
        text-transform: none;
        width: 80%;
        @media screen and (max-width:768px){
            font-size:small;
            font-weight: 200;
            letter-spacing: 1px;
            text-align: center;

        }

    }
}
.figures{
    display: flex;
    gap:2rem;
    >div{
        display: flex;
        flex-direction: column;
    }    
    >div>span:nth-of-type(1){
        color: white;
        font-size: 2rem;
        @media screen and (max-width:768px){
            font-size: large;
        }
    }
    >div>span:nth-of-type(2){
        color: ${Gray};
        text-transform: uppercase;
        @media screen and (max-width:768px){
            font-size: small;
        }
    }
}
.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
    @media screen and (max-width:768px){
        justify-content: center;
    }
    >:nth-child(1){
        color: white;
        background-color: ${Orange};
        width:8rem;
    }
    >:nth-child(2){
        color: white;
        background-color: transparent;
        border: 2px solid ${Orange};
        width:8rem;
    }
}

/* righ hero starts */
.right-hero{
    position: relative;
    flex:1;
    background-color: ${Orange};
    @media screen and (max-width:768px){
            background: none;
        }
    >button{
     position: absolute;
     right: 3rem;
     top: 2rem;
     color: black;
    }
    .heart-rate{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: ${DarkGrey};
        width: fit-content;
        padding: 1rem;
        align-items: flex-start;
        border-radius: 5px;
        position: absolute;
        right: 4rem;
        top: 7rem;
        @media screen and (max-width:768px){
            left: 1rem;
            top: 2rem;
        }
        img{
            width: 2rem;
        }
        >span:nth-child(2){
           color: ${Gray};
        }
        >span:nth-child(3){
            color: white;
            font-size: 1.5rem;
        }

    }
    .hero-img{
        position: absolute;
        top: 10rem;
        right: 7rem;
        width: 23rem;
        @media screen and (max-width:768px){
            position: relative;
            width: 15rem;
            left: 7rem;
            top: 4rem;
            align-self: center
        }

    }
    .hero_image-back{
position: absolute;
top: 4rem;
right: 20rem;
z-index: -1;
width: 15rem;
@media screen and (max-width:768px){
    width: 15rem;
    left: 2rem;
    top: 0rem;
        }
    }
    .calories{
        display: flex;
        gap: 2rem;
        background-color: ${CaloryCard};
        border-radius: 5px;
        padding: 1rem;
        max-width: 100%;
        width: 70%;
        position: absolute;
        top: 32rem;
        right: 28rem;
        @media screen and (max-width:768px){
            position: relative;
            top: 5rem;
            left: 2rem;
        }
        >img{
            width: 3rem;
            @media screen and (max-width:768px){
                width: 2rem;
        }
        }
        >div{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        >div>span:nth-child(1){
            color:${Gray};
        }
        >div>span:nth-child(2){

            color: white;
            font-size: 1.5rem;
            @media screen and (max-width:768px){
                color: white;
                font-size: 1rem;
        }
        }
    }
}


}









`


export default HeroStyled