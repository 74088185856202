import React from 'react'
import { Blurr, GeneralButton, Stroke_Text } from '../globalstyles/global'
import PlansStyle from './planstyle'
import {plansData} from '../../data/plansData'
import whitetick from '../../assets/whiteTick.png'
const plan = () => {
  return (
    <PlansStyle>
        <section className="plans-container" id='plans'>
            <Blurr className='plan-blurr1'></Blurr>
            <Blurr className='plan-blurr2'></Blurr>
            <div className="programs-header" style={{gap:"2rem"}}>
          <Stroke_Text>READY TO START</Stroke_Text>  
          <span>YOUR JOUNERY</span>
          <Stroke_Text>NOW WITH US</Stroke_Text>
            </div>
            {/* plan-cards */}
            <article className="plans">
                {plansData.map(({name,features,icon,price},i)=>{
                    return(
                    <div className="plan" key={i}>
                        {icon}
                        <span>{name}</span>
                        <span>${price}</span>
                        <div className='features'>
                            {features.map((feature,i)=>{
                           return(
                            <div className="feature">
                                <img src={whitetick} alt="" />
                                <span key={i}>{feature }</span>
                            </div>
                           )
                            })}</div>
                            <div>
                                <span>see more benefits --></span>
                            </div>
                            <GeneralButton>
                                join now
                            </GeneralButton>
                    </div>
                
                    )
                })}
            </article>
        </section>
    </PlansStyle>
  )
}

export default plan